<!--
 * @Author: dream486 “feimengss@163.com
 * @Date: 2023-10-08 11:18:55
 * @LastEditors: dream486 “feimengss@163.com
 * @LastEditTime: 2024-05-21 16:02:29
 * @FilePath: \feidream2简化版\src\pages\minebook\components\mytextarea.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
<div class="bigwrap">
  <div class="art-title"><span class="iconfont backhome" @click="backhome">&#xe697;</span>{{area.title}}</div>
  <div class="textarea">
    <p class="time">发布时间：{{area.time}}</p>
    <!-- <pre class="area" style="white-space: pre-wrap;">
      {{area.textarea}}
    </pre> -->
    <div class="area" v-html="area.textarea"></div>
  </div>
</div>
</template>

<script>
import moment from 'moment'
export default {
  name: 'mytextarea',
  data () {
    return {
      id: this.$route.params.id, // id122212
      area: ''
    }
  },
  created () {
  },
  methods: {
    backhome () {
      this.$router.go(-1)
    }
  },
  mounted () {
    this.area = this.$route.query.textarea
    console.log(this.area)
    this.area.time = moment(this.area.time).format('YYYY-MM-DD HH:mm:ss')
    this.area.textarea = this.area.textarea.replace(/shift7nbsp;/g, ' ')
    this.area.textarea = this.area.textarea.replace(/HHbr/g, '\n')
    this.area.textarea = this.area.textarea.replace(/shift7/g, '&')
  }
}
</script>
<style lang="stylus" scoped>
.art-title
  height 1rem
  width 100%
  text-indent 1em
  background-color #4D5963
  color #fff
  font-size 0.35rem
  line-height 1rem
  position fixed
  top 0
.bigwrap
  background-color white
  // min-height 100vh
  width 100%
  .textarea
    margin-top 1rem
    .time
      text-indent 1em
      color blue
      line-height .6rem
      background-color white
    .area
      text-indent: 1em;
.sendft
  height 1rem
.backhome
  font-size 0.3rem
  margin-right 0.2rem
  cursor pointer
</style>
