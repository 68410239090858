<!--
 * @Author: dream486 “feimengss@163.com
 * @Date: 2024-05-21 12:42:08
 * @LastEditors: dream486 “feimengss@163.com
 * @LastEditTime: 2024-05-21 16:26:01
 * @FilePath: \feidream2简化版\src\pages\minebook\addArticle2.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div class="add-wrap">
    <div v-wechat-title="$route.meta.title"></div>
    <!-- 导航页 -->
    <navmenu class="navmenu"></navmenu>
    <!-- 空白隔开 -->
    <!-- <div style="height:90px;width:100%"></div> -->
    <div class="add-contant-wrap">
      <div>
        标题：<input type="input" v-model="artTitle">
      </div>
      <vue-editor v-model="myarticle"></vue-editor>
      <div class="send-but" @click="sendart">提交</div>
      <!-- <div v-html="myarticle"></div> -->
    </div>
  </div>
</template>

<script>
import navmenu from './components/navmenu.vue'
import { mapState } from 'vuex'
import moment from 'moment'
import { VueEditor } from 'vue2-editor'
export default {
  name: 'addArticle',
  components: {
    navmenu,
    VueEditor
  },
  computed: {
    ...mapState({
      Username: 'Username'
    })
  },
  data () {
    return {
      artTitle: '',
      myarticle: '',
      mydate: moment(Date()).format('YYYY-MM-DD HH:mm:ss'),
      fileList: [
        // { url: '' }
      ],
      index: 0
    }
  },
  methods: {
    sendart () {
    //   this.myarticle = this.myarticle.replace(/\n/g, 'HHbr')
    //   this.myarticle = this.myarticle.replace(/ /g, 'shift7nbsp;')
    //   this.myarticle = this.myarticle.replace(/&/g, 'shift7')
      // this.myarticle = this.myarticle.replace(/(\n\r|\r\n|\r|\n)/g, '<br/>')
    //   this.myarticle = this.myarticle.replace('#', '井')
      if (!this.artTitle) {
        alert('请输入标题')
      } else if (!this.myarticle) {
        alert('请输入内容')
      } else {
        this.$http.post(`/addarea?name=${this.Username}&title=${this.artTitle}&time=${this.mydate}&textarea=${this.myarticle}`).then(function (res) {
          console.log(res.data)
        })
        this.artTitle = ''
        this.myarticle = ''
      }
    }
  },
  mounted () {
  }
}
</script>
<style lang="stylus" scoped>
.navmenu
  z-index 999
  opacity 0.9
  width 100%
  position fixed
  top 0
  left 0
.add-wrap
  width 100%
  overflow: hidden
  .add-contant-wrap
    width: 100%
    // height: calc(100% - 90px)
    position: fixed
    top: 90px
    left: 0
  .send-but
    width: 150px
    height: 50px
    line-height: 50px
    border-radius: 15px
    text-align: center
    background: yellow
    margin-top 10px
    cursor pointer
  .send-but:hover
    background: red
    color: white
</style>
