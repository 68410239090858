import Vue from 'vue'
import VueRouter from 'vue-router'
import navigations from '@/pages/home/navigations.vue' // 首页导航页
import home from '@/pages/home/home.vue'
import navigation from '@/pages/home/components/navigation.vue' // home页导航页
import minebook from '@/pages/minebook/minebook.vue' // 个人博客首页
import login from '@/pages/login/index'
import pwd from '@/pages/login/pwd'
import vd from '@/pages/login/vd'
import mytextarea from '@/pages/minebook/components/mytextarea.vue'
import addArticle from '@/pages/minebook/addArticle2.vue'
import demo from '@/pages/demo.vue'
import phone from '@/pages/chatroom/phone.vue'
import game from '@/pages/game/index.vue'
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push (location) {
  return originalPush.call(this, location).catch(err => err)
}
Vue.use(VueRouter)
const routes = [{
  path: '/home',
  name: 'home',
  component: home
}, {
  path: '/navigations',
  name: 'navigations',
  component: navigations
}, {
  path: '/login',
  name: 'login',
  component: (resolve) => require(['@/pages/home/components/login.vue'], resolve)
}, {
  path: '/vd',
  name: 'vd',
  component: vd
}, {
  path: '/demo',
  name: 'demo',
  component: demo
}, {
  path: '/pwd',
  name: 'pwd',
  component: pwd
}, {
  path: '/chatRoom',
  name: 'chat',
  component: phone,
  meta: {
    title: '聊天室',
    requireAuth: true
  }
}, {
  path: '/login',
  name: 'login',
  component: login
},
// meta: {
//   requireAuth: true // 添加该字段，表示进入这个路由是需要登录的
// }
// {
//   path: '/whether',
//   name: 'whether',
//   component: whether,
//   beforeEnter: (to, from, next) => { // 路由独享守卫，路由拦截
//     console.log(from.name, to.name)
//     if (from.name !== to.name) {
//       next()
//     }
//     if (from.name === null && to.name === 'whether') {
//       next(router.go(-1))
//     } else {
//       next()
//     }
//   }
// },
{
  path: '/',
  name: 'minebook',
  component: minebook,
  meta: {
    title: '学习经验分享',
    requireAuth: true
  }
}, {
  path: '/addArticle',
  name: 'addArticle',
  component: addArticle,
  meta: {
    title: '文章新增页'
  }
}, {
  path: '/mytextarea/:id',
  name: 'mytextarea',
  component: mytextarea,
  meta: {
    title: '文章详情页'
  }
}, {
  path: '/navigation',
  name: 'navigation',
  component: navigation
}, {
  path: '/game',
  name: 'game',
  component: game
}]

const router = new VueRouter({
  routes
})
router.beforeEach((to, from, next) => {
  window.document.title = to.meta.title
  next()
})
// 跳转后设置scroll为原点
router.afterEach((to, from, next) => {
  window.scrollTo(0, 0)
})
// router.beforeEach((to, from, next) => {
//   if (to.meta.requireAuth === true) {
//     next()
//   } else {
//     alert('请登录')
//     next(router.push('/login'))
//   }
// })
Vue.prototype.$lv = {
  $router: router,
  go (path, query) {
    /* 路由跳转 */
    const next = () => this.$router.push({ path, query })
    if (this.$router.options.routes.some(route => route.name === path)) {
      next()
    } else {
      /* 页面分开打包，按需加载，只打包以pages结尾的.vue文件，不以page结尾的表示页面公共的组件，分开打包，按需引入 */
      import('@/pages' + path + '.vue').then(module => {
        const route = { name: path, path: path, component: module.default }
        /* 添加路由信息 */
        this.$router.options.routes.push(route)
        this.$router.addRoutes([route])
        next()
      })
    }
  },
  back () {
    window.history.back()
  }
}
export default router
