<template>
  <div class="big-wrap" :style="conTop">
    <div class="nav">
      <div class="left" @click="left"><span class="iconfont">&#xe697;</span></div>
      <div>聊天室</div>
      <!-- <div><a @click="gohome">返回首页</a></div> -->
      <div></div>
    </div>
    <div class="chatroom">
      <!-- 聊天内容 -->
      <div class="histroyChats" ref="chatroom" :class="{'histroyChats2':littletap == true}" @click="tabhidden">
        <!-- 单条信息 -->
        <div class="messageList" :style="{'flex-direction': item.right? 'row-reverse' : 'row'}" v-for="item in messageList" :key="item.id">
          <!-- 头像 -->
          <span class="userImg">
            <!-- <img :src="'data:image/jpeg;base64,'+chatimg" alt=""> -->
            <img :src="item.headimgs" alt="">
          </span>
          <!-- 昵称 -->
          <div class="messageRight">
            <span :style="{'text-align': item.right? 'right' : 'left'}">
              {{item.nickname && item.nickname != 'null' ? item.nickname : '游客' + item.uid}}
            </span>
            <!-- 内容 -->
            <span>{{ item.msg }}</span>
          </div>
        </div>
      </div>
      <!-- 编辑框 -->
      <div class="editBox" :class="{'tabshow':littletap == true}">
        <!-- 小编辑框 -->
        <div class="little-edit">
          <div class="little-edit-1"><el-input placeholder="请输入聊天内容" type="text" v-model="chatText"></el-input></div>
          <div class="little-edit-2" @click="sendMsg">发送</div>
        </div>
        <!-- 小工具 -->
        <div class="gadget">
          <span class="iconfont" @click="littleFace(index)" v-for="(gadget,index) in gadget" :key="index" :class="{active:index == tabNum}"><span :class="gadget"></span></span>
        </div>
        <!-- 大编辑框 -->
        <div class="big-edit">
          <div class="gadgets" v-for="(navtab,index) in navtab" :key="index" v-show="index == tabNum">{{navtab}}</div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex'
import moment from 'moment'
export default {
  name: 'phone',
  components: {
  },
  computed: {
    ...mapState({
      Username: 'Username'
    })
  },
  data () {
    return {
      imgurls: '',
      conTop: {},
      messageList: [],
      littletap: false,
      gadget: ['icon-maikefeng-', 'icon-tupian', 'icon-xiangji', 'icon-hongbao', 'icon-biaoqing', 'icon-jia'],
      navtab: ['tab1', 'tab2', 'tab3', 'tab4', 'tab5', 'tab6'],
      tabNum: '',
      chatText: '',
      uid: '',
      socket: null
    }
  },
  created () {

  },
  methods: {
    getinfo () {
      this.$http.get('/getchat').then(res => {
        console.log(res.data)
        if (res.data.data && res.data.data.length > 0) {
          res.data.data.forEach(item => {
            const message = item
            if (message.uid === this.uid || (message.nickname === this.Username && message.nickname !== 'null')) {
              message.right = true
            }
            this.messageList.push(message)
            this.$nextTick(() => {
              this.$refs.chatroom.scrollTop = this.$refs.chatroom.scrollHeight
            })
          })
        }
      })
    },
    getheadimg () {
      this.$http.get('/getchatusers').then(res => {
        this.headimgs = res.data
        console.log(this.headimgs)
      })
    },
    left () {
      this.$router.push('/')
    },
    littleFace (index) {
      this.littletap = true
      this.tabNum = index
    },
    tabhidden () {
      this.littletap = false
    },
    gohome () {
      this.$router.push('/navigations')
    },
    // 发送信息
    sendMsg () {
      if (!this.chatText) {
        return
      }
      // this.messageList.push({
      //   msg: this.chatText
      // })
      this.sendMessage(2, this.chatText)
    },
    sendMessage (type, msg) {
      console.log(this.socket.readyState, this.socket)
      // 添加状态判断，当为OPEN时，发送消息
      if (this.socket.readyState === 1) {
        if (type === 2) {
          this.$http.post(`/addchat?userName=${this.Username}&msg=${msg}&uid=${this.uid}`)
        }

        this.socket.send(JSON.stringify({
          uid: this.uid,
          type: type,
          nickname: this.Username,
          msg: msg
        }))
        this.chatText = ''
      } else {
        // do something
      }
    },
    conWebSocket () {
      const vm = this
      if (window.WebSocket) {
        vm.socket = new WebSocket('ws://114.132.183.65:8001')
        // vm.socket = new WebSocket('ws://broker.emqx.io:8083/mqtt')
        const socket = vm.socket

        socket.onopen = function (e) {
          console.log('连接服务器成功')
          if (!vm.uid) {
            // 生成新的用户id,并存入localStorage
            vm.uid = 'web_im_' + moment().valueOf()
            localStorage.setItem('WEB_IM_USER', JSON.stringify({
              uid: vm.uid,
              nickname: vm.Username
            }))
            vm.sendMessage(1)
          }
        }
        socket.onclose = function (e) {
          console.log('服务器关闭')
        }
        socket.onerror = function () {
          console.log('连接出错')
        }
        // 接收服务器的消息
        socket.onmessage = function (e) {
          console.log('接受的消息', e.data)
          const message = JSON.parse(e.data)
          console.log(message.uid === vm.uid, message.nickname === vm.Username, message.nickname !== 'null', message.nickname, vm.Username)
          if (message.type === 2 && (message.uid === vm.uid || (message.nickname === vm.Username && message.nickname !== 'null' && message.nickname !== null))) {
            message.right = true
          }
          vm.messageList.push(message)
          vm.$nextTick(() => {
            vm.$refs.chatroom.scrollTop = vm.$refs.chatroom.scrollHeight
          })
        }
      }
    }
  },
  mounted () {
    this.getinfo()
    this.getheadimg()
    const vm = this
    vm.nickname = this.Username

    vm.conWebSocket()
    document.onkeydown = function (event) {
      var e = event || window.event
      if (e && e.keyCode === 13) { // 回车键的键值为13
        vm.sendMsg()
      }
    }
  }
}
</script>
<style lang="stylus" scoped>
.big-wrap
  width 100%
  // height 13rem
  background-attachment fixed
  position relative
.nav
   justify-content space-between
   display -webkit-flex
   display flex
   height 0.8rem
   line-height 0.8rem
   background-color grey
   .iconfont
     font-size 0.4rem
.chatroom
  height calc(100vh - 0.8rem)
  // background-color grey
  position relative
.histroyChats
  height calc(100vh - 0.8rem - 1rem)
  padding-bottom 1.2rem
  overflow-y scroll
  width 100%
  background-color pink
.histroyChats2{
  height calc(100vh - 0.8rem - 4rem)
}
.editBox
  height 1.3rem
  width 100%
  position fixed
  bottom 0
  left 0
  background-color green
  padding-bottom 0.3rem
.tabshow
  height 4rem
.little-edit
  height 0.5rem
  width 100%
  background-color green
  color white
  .little-edit-1
    float left
    min-width 5rem
    input
      width 100%
      border-radius 0.5rem
      margin-left 0.2rem
      text-indent 0.5em
  .little-edit-2
    float left
    display block
    width 2rem
    line-height: 0.5rem
    text-align center
    cursor pointer
.gadget
  height 0.8rem
  margin-top 0.2rem
  width 100%
  display flex
  display -webkit-flex
  justify-content space-between
  span
    display block
    text-align center
    color yellow
    min-width 1rem
.active
  color white
.big-edit
  width 100%
  height 3rem
  background-color red
  div
    width 100%
    height 3rem
.messageList
  width 100%
  min-height 1rem
  margin-bottom 0.3rem
  display flex
  span
    display block
.userImg
  width 1rem
  height 1rem
  background-color black
  border-radius 50%
  line-height 1.2rem
  margin-left .2rem
  overflow hidden
  img
    width 100%
    height 100%
.messageRight
  margin-left 0.2rem
  span:nth-child(1)
    height 0.3rem
    font-size 0.3rem
    color black
    line-height 0.3rem
  span:nth-child(2)
    background-color white
    padding 0 0.2rem
    font-size 0.4rem
    line-height 0.5rem
    border-radius 0.2rem
    max-width calc(100vw - 1.5rem)
    word-wrap: break-word; /* 旧版浏览器 */
    overflow-wrap: break-word; /* 新版标准 */
</style>
<style>
.little-edit-1 .el-input__inner {
  width: 100%;
  margin-left: 0.2rem;
  text-indent: 0.5em;
  height: 0.5rem;
}
</style>
