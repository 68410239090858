<template>
<div class="bigwrap">
  <div class="textarea" @mouseenter="moveart(item.id)" @mouseleave="leaveart(item.id)" :class="{'pinkui':mobilepinkui == true,'textarea-mobile':ismobile == true}" v-for="(item, index) in allarealist" :key="index">
    <div class="arttext-box">
    <!-- <div @click="totextarea(item.id,index,item)" class="arttext-box" :class="{'movelit': movearts == item.id,'leavelit': leavearts == item.id}"> -->
      <!-- <img class="artimg" src="../../../assets/img/api.vvhan.com[429].jpg" alt=""> -->
      <div class="arttext">
        <p class="art-title" @click="totextarea(item.id,index,item)">{{item.title}}</p>
        <!-- <div class="area">{{item.textarea}}</div> -->
        <div v-html="item.textarea"></div>
      </div>
    </div>
    <!-- <div class="artmsg" :class="{'movelib': movearts == item.id,'leavelib': leavearts == item.id}"> -->
    <div class="artmsg movelib">
      <span class="time">{{item.time}}</span>
      <span class="iconfont">&#xe6c8;</span><span>{{item.name}} </span>
      <span @click="addgood(index,item)" class="iconfont">&#xe60c;</span><span>{{item.good}} </span>
      <span class="iconfont">&#xe648;</span><span>{{item.see}}</span>
    </div>
    <div class="goDetailText" @click="totextarea(item.id,index,item)">查看详情</div>
  </div>
  <div class="page-bar" :class="{'pinkui2':mobilepinkui == true}">
    <ul>
      <li v-if="cur>1"><a v-on:click="cur--,pageClick()">上一页</a></li>
      <li v-if="cur==1"><a class="banclick">上一页</a></li>
      <li v-for="index in indexs" class="numli" :key="index" v-bind:class="{'active': cur == index}">
        <a @click="btnClick(index)">{{index}}</a>
      </li>
      <li v-if="cur!=all"><a @click="cur++,pageClick()">下一页</a></li>
      <li v-if="cur == all"><a class="banclick">下一页</a></li>
      <li><a>共<i>{{all}}</i>页</a></li>
    </ul>
  </div>
  <!-- 尾部 -->
  <footer @click="gongxinbu('https://beian.miit.gov.cn')">豫ICP备20009133号-1</footer>
</div>
</template>

<script>
import moment from 'moment'
export default {
  name: 'myarticle',
  data () {
    return {
      arealist: '',
      pinkui: Boolean(this.$route.query.pinkui),
      allarealist: [],
      // Index: 1,
      all: 10, // 总页数
      cur: 1, // 当前页码
      totalPage: 0, // 当前条数
      mobilepinkui: false,
      movearts: null,
      leavearts: null,
      artgood: null,
      ismobile: false // 手机端适配
    }
  },
  methods: {
    gongxinbu (url) {
      location.href = url
    },
    totextarea (itemId, index, item) { // 跳转到详情，浏览量加1
      this.artgood = this.allarealist[index].see + 1
      this.$http.put(`/updateareasee?see=${this.artgood}&id=${item.id}`).then(res => {
        // console.log(res)
      })
      this.$router.push({ path: '/mytextarea/' + itemId, query: { textarea: item } })
    },
    addgood (index, it) { // 点赞
      this.artgood = this.allarealist[index].good + 1
      this.$http.put(`/updateareagood?good=${this.artgood}&id=${it.id}`).then(res => {
        console.log(res.data)
      })
      this.allarealist[index].good = this.artgood
    },
    getarea (index) {
      this.$http.get('/getarea', {
        params: {
          page: index,
          limit: '10',
          state: 0
        }
      }).then(res => {
        this.all = Math.ceil(res.data.data.length / 10)
        this.allarealist = []
        for (let i = 0; i <= res.data.data.length; i++) {
          if (Math.ceil(i / 10) === Math.ceil(index)) {
            this.allarealist.push({
              ...res.data.data[i - 1],
              time: moment(res.data.data[i - 1].time).format('YYYY-MM-DD HH:mm:ss'),
              // textarea: res.data.data[i - 1].textarea ? res.data.data[i - 1].textarea.replace(/shift7nbsp;/g, ' ').replace(/HHbr/g, '\n').replace(/shift7/g, '&') : ''
              textarea: res.data.data[i - 1].textarea
            })
            // console.log(this.allarealist)
          }
        }
      })
    },
    moveart (index) {
      this.movearts = index
    },
    leaveart (index) {
      this.leavearts = index
    },
    // 分页
    btnClick (data) { // 页码点击事件
      if (data !== this.cur) {
        this.cur = data
      }
      // 根据点击页数请求数据
      this.getarea(this.cur.toString())
    },
    pageClick () {
      this.getarea(this.cur.toString())
    },
    mobile () { // 手机端背景适配
      if (this.pinkui === true) {
        if (/Android|webOS|iPhone|iPod|BlackBerry/i.test(navigator.userAgent)) {
          this.mobilepinkui = false
          this.ismobile = true
        } else {
          this.mobilepinkui = true
          this.ismobile = false
        }
      }
      if (/Android|webOS|iPhone|iPod|BlackBerry/i.test(navigator.userAgent)) {
        this.ismobile = true
      } else {
        this.ismobile = false
      }
    }
  },
  computed: {
    // 分页
    indexs () {
      var left = 1
      var right = this.all
      var ar = []
      if (this.all >= 5) {
        if (this.cur > 3 && this.cur < this.all - 2) {
          left = this.cur - 2
          right = this.cur + 2
        } else {
          if (this.cur <= 3) {
            left = 1
            right = 5
          } else {
            right = this.all
            left = this.all - 4
          }
        }
      }
      while (left <= right) {
        ar.push(left)
        left++
      }
      return ar
    }
  },
  mounted () {
    this.getarea(1)

    this.mobile()
  }
}
</script>
<style lang="stylus" scoped>
// 分页
.page-bar
  margin-top 1.2rem
  width 100%
  text-align left
  position relative
  ul,li
    margin 0
    padding 0
    list-style none
  ul
    margin 0 auto
    width 90%
    position absolute
    left 0
    right 0
    bottom 0.5rem
  li
    float left
    margin-right 0.2rem
  .numli
    width 0.5rem
  li:first-child>a
    margin-left 0
  a
    border 0.01rem solid #ddd
    text-decoration none
    position relative
    padding 0.1rem 0.2rem
    line-height 1.42857143
    color #5d6062
    cursor pointer
    background-color #fff
  a:hover
    background-color #eee
  a.banclick
    cursor not-allowed
  .active a
    color #fff
    cursor default
    background-color #e96463
    border-color #e96463
  i
    font-style normal
    color #d44950
    margin 0 0.04rem
    font-size 0.2rem
.pinkui2
  max-width 8rem
  margin-left 1rem
.textarea:hover
  // box-shadow 0.08rem 0.07rem 0.2rem grey
  // margin-top 0.5rem
.textarea
  height 2rem
  height auto
  background-color #fff
  width 100%
  margin 0 auto
  margin-bottom 0.3rem
  text-align center
  overflow hidden
  border-radius .1rem
  position relative
  .artimg
    width 36%
    margin-left 2%
    float left
    height 1.6rem
    height auto
    margin-top 0.2rem
    margin-bottom 0.2rem
    img
      width 100%
      height auto
  .arttext
    float left
    width 60%
    width: 100%
    // margin-left 2%
    text-align: left
    height 1.6rem
    height auto
    padding-bottom .3rem
    margin-top 0.2rem
    margin-bottom 0.2rem
    .art-title
      cursor pointer
    .area
      font-size 0.2rem
      text-overflow ellipsis
      overflow hidden
      display -webkit-box
      word-break break-all
      -webkit-box-orient vertical
      -webkit-line-clamp 4   // 2行后隐藏
      line-clamp 4
      margin-top 0.05rem
      // width 90%
      // margin-left 5%
    // p:nth-child(1)
    //   font-size 0.2rem
    p:nth-child(1)
      font-weight 600
      font-size 0.25rem
      overflow:hidden; /*溢出的部分隐藏*/
      white-space: nowrap; /*文本不换行*/
      text-overflow:ellipsis;
.arttext-box
  width 100%
  height 100%
.artmsg
  // width 100%
  position absolute
  bottom 0rem
  right .2rem
  height 0.5rem
  // background-color #282C34
  color #181824
  span
    font-size 0.2rem
    line-height 0.5rem
    cursor pointer
  .time
    font-size 0.2rem
    margin-right .2rem
.goDetailText
  position absolute
  bottom 0rem
  left 0
  height 0.5rem
  color #448fd5
  font-size 0.2rem
  line-height 0.5rem
  cursor pointer
.movelib
  transform translate(0,-0.5rem)
  transition ease-in-out 0.3s
.movelit
  transform translate(0,-0.5rem)
  transition ease-in 0.3s
.leavelib
  transform translate(0,0rem)
  transition ease-in-out 0.3s
.leavelit
  transform translate(0,0rem)
  transition ease-in 0.3s
.pinkui
  margin-left 1rem
.textarea-mobile // 手机端
  height 5rem
  .artimg
    width 96%
    height 3.2rem
  .arttext
    width 96%
    .area
      font-size .12rem
      -webkit-line-clamp 1  // 2行后隐藏
      line-clamp 1
footer
  width 100%
  text-align center
  line-height .5rem
  color #333
  background-color #fff
  opacity 1
  z-index 999
  cursor pointer
</style>
