<template>
<div style="position:relative;">
  <div class="newHeadMenu-box">
    <div class="newLogo">mineBook</div>
    <div class="newCenter">
      <div @click="goArtList" class="center-item">文章列表</div>
      <div @click="goAddArt" class="center-item">添加文章</div>
      <div @click="goChatRoom" class="center-item">聊天室</div>
      <!-- <div @click="goGame" class="center-item">遊戲</div> -->
    </div>
    <div class="newUser">
      <span class="iconfont username">&#xe6c8;</span>{{Username?Username:'请登录'}}
      <a class="newLogin" @click="login" v-if="!username">(登录)</a>
      <a class="newLogin" @click="outlogin" v-else>(退出登录)</a>
    </div>
  </div>
<div class="addart1" :class="{'moveright':addartleft == true,'moveleft':addartleft == false}">
  <span id="tle1">文章</span>
  <textarea name="" placeholder="添加内容：" id="art-addtextarea" cols="30" rows="10" onFocus="" @keyup.enter="onWrite" onBlur="" v-model="myarticle"></textarea>
  <button @click="sendart">发布</button>
  <button id="backbtn" @click="addart">返回</button>
</div>
<div class="addart2" :class="{'moveright':addartleft == false,'moveleft':addartleft == true}">
  <span id="tle2">添加</span>
  <input type="text" id="art-addtitle" placeholder="添加标题：" v-model="artTitle">
  <p>添加图片</p>
  <div id="add-artimg">
    <van-uploader v-model="fileList" multiple :after-read="afterRead" :preview-full-image="false" :max-count="5">
      <van-button icon="plus" type="primary">上传文件</van-button>
    </van-uploader>
  </div>
</div>
</div>
</template>

<script>
import { mapState } from 'vuex'
export default {
  name: 'minebookNavmenu',
  inject: ['reload'],
  computed: {
    ...mapState({
      Username: 'Username'
    })
  },
  data () {
    return {
      menuList: false,
      girls: false,
      pinkui: false,
      username: this.$store.state.username,
      loginStatus: '',
      artTitle: '',
      myarticle: '',
      titleimg: '',
      addartleft: false,
      mydate: Date(),
      fileList: [
        // { url: '' }
      ]
    }
  },
  methods: {
    afterRead (file) {
      // 此时可以自行将文件上传至服务器
      this.titleimg = ''
      console.log(file)
      // file.status = 'uploading'
      // file.message = '上传中...'

      // setTimeout(() => {
      //   file.status = 'failed'
      //   file.message = '上传失败'
      // }, 1000)
    },
    onWrite () {

    },
    sendart () {
      if (this.artTitle === '' || this.myarticle === '') {
        alert('请输入内容')
      } else {
        this.myarticle = this.myarticle.replace(/\n/g, 'HHbr')
        this.myarticle = this.myarticle.replace(/ /g, 'shift7nbsp;')
        this.myarticle = this.myarticle.replace(/&/g, 'shift7')
        // this.myarticle = this.myarticle.replace(/(\n\r|\r\n|\r|\n)/g, '<br/>')
        this.myarticle = this.myarticle.replace('#', '井')
        this.$http.post(`/addarea?name=${this.Username}&title=${this.artTitle}&titleimg=${this.titleimg}&time=${this.mydate}&textarea=${this.myarticle}`).then(function (res) {
          console.log(res.data)
        })
        this.artTitle = ''
        this.myarticle = ''
        this.addartleft = !this.addartleft
        this.reload()
      }
    },
    handleOpen (key, keyPath) {
      console.log(key, keyPath)
    },
    handleClose (key, keyPath) {
      console.log(key, keyPath)
    },
    menulist () {
      this.menuList = !this.menuList
    },
    goback () {
      this.$router.push('/navigations')
    },
    login () {
      this.$router.push('/login')
    },
    // 跳转到聊天室
    goChatRoom () {
      this.$router.push('/chatRoom')
    },
    goGame () {
      this.$router.push('/game')
    },
    outlogin () {
      this.loginStatus = 'logout'
      this.$router.push({ name: 'login', params: { loginStatus: this.loginStatus } })
    },
    addart () {
      // this.$router.push('/addArticle')
      this.menuList = false
      this.addartleft = !this.addartleft
    },
    goArtList () {
      this.$router.push('/')
    },
    goAddArt () {
      this.$router.push('/addArticle')
    }
  },
  mounted () {
    console.log(this.username)
  }
}
</script>
<style lang="stylus" scoped>
.newHeadMenu-box
  height 90px
  width: 100%
  background: #08A3D9
  position fixed
  top 0
  left 0
  padding: 0 8%
  line-height: 90px
  display: flex
  justify-content: space-between
  box-sizing: border-box
  .newLogo
    color:#fff;
    cursor pointer
  .newCenter
    display: flex
    color:#fff;
    margin-left -20px
    .center-item
      margin-left 20px
      cursor pointer
      font-size: 20px
  .newUser
    color: #fff
    font-size: 20px
    .newLogin
      font-size: 20px
      cursor pointer
      margin-left 20px
      // color: #fff
#headmenu // 头部导航栏
  background-color:#545c64;
  height:0.5rem;
  width 100%
  line-height:0.5rem;
  color:#fff;
  text-align:center;
.username
  font-size 0.2rem
  margin-right 0.1rem
.navmenu
  position relative
  .menu-list
    position absolute
    top 0
    left -100%
    text-align center
    line-height 0.5rem
    width 100%
    background-color #545c64
    font-size 0.2rem
    z-index 999
    div
     border-top  0.01rem solid #fff
.addart1
  background-color #F1F3F4
  width 50%
  height calc(100vh - .5rem)
  position fixed
  top 0.5rem
  left 0
  overflow hidden
  button
    width 1rem
    height 0.45rem
    font-size 0.3rem
    margin-top 1rem
    float right
    margin-right 0.5rem
    color #eee
    background-color #e96463
  #backbtn
    float left
    margin-left 0.5rem
.addart2
  background-color white
  width 50%
  overflow hidden
  height calc(100vh - .5rem)
  position fixed
  top 0.5rem
  right 0
  #add-artimg
    width 90%
    margin-left 5%
  p
    font-size 0.2rem
    line-height 0.8rem
    color black
    text-indent 1em
.moveright
  transform translate(100%,0)
  transition all 1s ease-in-out
.moveleft
  transform translate(-100%,0)
  transition all 1s ease-in-out
#tle1
  float left
  font-size 0.2rem
#tle2
  font-size 0.2rem
  float right
#art-addtextarea
  width 90%
  color black
  margin-left 5%
  margin-top 0.3rem
  border-style none
#art-addtitle
  color black
  border-style none
  border-bottom 0.02rem solid #e96463
  width 94%
  margin-left 3%
  margin-top 0.3rem
  height 0.6rem
</style>
