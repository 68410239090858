<template>
  <div ref="wraps" class="game-bigwrap">
    <ul>
      <li @click="backhome">返回首页</li>
      <li @click="$lv.go('/game/components/threejs/threejs')">
        <span class="iconfont">&#xe6cc;</span> threejs <span>学习中</span>
      </li>
      <li @click="$lv.go('/game/components/threejs/threejs2')">
        <span class="iconfont">&#xe6cc;</span> threejs挑一挑 <span>学习中</span>
      </li>
      <li @click="$lv.go('/game/components/threejs/demo1')">
        <span class="iconfont">&#xe6cc;</span> demo1 <span>学习中</span>
      </li>
      <li @click="$lv.go('/game/components/pinTu/pinTu')">
        <span class="iconfont">&#xe6cc;</span> 拼图 <span>制作完成</span>
      </li>
      <li @click="$lv.go('/game/components/zhiWu/zhiWu')">
        <span class="iconfont">&#xe6cc;</span> 植物大战僵尸 <span>进行中</span>
      </li>
      <li @click="$lv.go('/game/components/piano/piano')">
        <span class="iconfont">&#xe6cc;</span> 钢琴 <span>制作完成</span>
      </li>
    </ul>
  </div>
</template>
<script>
export default {
  name: 'game',
  data () {
    return {
    }
  },
  methods: {
    backhome () {
      this.$router.push('/navigations')
    }
  },
  mounted () {
  }
}
</script>
<style lang="stylus">
*
  margin 0
.game-bigwrap
  height 100%
  ul li
  line-height 0.8rem
  background-color #eee
  border-bottom 0.02rem solid pink
  .iconfont
    font-size 0.4rem
</style>
