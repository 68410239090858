<!--
 * @Author: dream486 “feimengss@163.com
 * @Date: 2024-05-28 14:16:16
 * @LastEditors: dream486 “feimengss@163.com
 * @LastEditTime: 2024-05-28 18:02:04
 * @FilePath: \feidream2简化版\src\pages\demo.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
    <div class="big-wrap">
        <div class="table-title">东山软件公司员工应聘登记表</div>
        <div class="table-tab-box">
            <div class="tab-tab-item" :class="{'actived': tabChecked == 0}" @click="tabChecked = 0">基本信息</div>
            <div class="tab-tab-item" :class="{'actived': tabChecked == 1}" @click="tabChecked = 1">工作经历</div>
            <div class="tab-tab-item" :class="{'actived': tabChecked == 2}" @click="tabChecked = 2">家庭成员</div>
        </div>
        <!-- 基本信息 -->
        <div class="base-msg-wrap" v-if="tabChecked == 0">
            <div class="table-tr">
              <div class="table-title-box">
                <div class="table-title-box-item bottom-none">姓名</div>
                <div class="table-title-box-item bottom-none">血型</div>
                <div class="table-title-box-item bottom-none">身高</div>
                <div class="table-title-box-item height-box bottom-none">婚姻情况</div>
              </div>
              <div class="blank-box-wrap">
                <div class="blank-box-item left-none bottom-none"><input type="text" v-model="user.username"></div>
                <div class="blank-box-item left-none bottom-none"><input type="text"></div>
                <div class="blank-box-item left-none bottom-none"><input type="text"></div>
                <div class="blank-box-item height-box left-none bottom-none">
                    <div class="marriage-check-box">
                        <el-radio v-model="marriage" label="1">已婚</el-radio>
                        <el-radio v-model="marriage" label="2">未婚</el-radio>
                        <el-radio v-model="marriage" label="3">其他</el-radio>
                    </div>
                </div>
              </div>
              <div class="table-title-box">
                <div class="table-title-box-item left-none bottom-none">曾用名</div>
                <div class="table-title-box-item left-none bottom-none">出生日期</div>
                <div class="table-title-box-item left-none bottom-none">体重</div>
                <div class="table-title-box-item left-none height-box double-line bottom-none">政治面貌<br/>加入时间
                </div>
              </div>
              <div class="blank-box-wrap  hight-width">
                <div class="blank-box-item hight-width left-none bottom-none"><input type="text"></div>
                <div class="blank-box-item hight-width left-none bottom-none"><input type="text" v-model="user.birthday"></div>
                <div class="blank-box-item hight-width left-none bottom-none">kg</div>
                <div class="blank-box-item height-box hight-width left-none bottom-none political-Landscape">
                    <div class="little-blank-box"><input type="text"></div>
                    <div class="little-title-box">宗教信仰</div>
                    <div class="little-blank-box2"><input type="text"></div>
                </div>
              </div>
              <div class="table-title-box">
                <div class="table-title-box-item left-none bottom-none">性别</div>
                <div class="table-title-box-item left-none bottom-none">民族</div>
                <div class="table-title-box-item left-none bottom-none">籍贯</div>
                <div class="table-title-box-item left-none height-box bottom-none">星座
                </div>
              </div>
              <div class="blank-box-wrap">
                <div class="blank-box-item left-none bottom-none"><input type="text" v-model="user.sex"></div>
                <div class="blank-box-item left-none bottom-none"><input type="text"></div>
                <div class="blank-box-item left-none bottom-none"><input type="text"></div>
                <div class="blank-box-item height-box left-none bottom-none">
                    <input type="text">
                </div>
              </div>
              <div class="head-img-box"></div>
            </div>
            <div class="table-tr">
                <div class="table-title-box">
                    <div class="table-title-box-item bottom-none">毕业学校</div>
                    <div class="table-title-box-item bottom-none">身份证号</div>
                </div>
                <div class="blank-box-wrap">
                    <div class="blank-box-item left-none bottom-none"><input type="text" v-model="user.school"></div>
                    <div class="blank-box-item left-none bottom-none"><input type="text" v-model="user.idcar"></div>
                </div>
                <div class="table-title-box">
                    <div class="table-title-box-item left-none bottom-none">学历</div>
                    <div class="table-title-box-item left-none bottom-none">移动电话</div>
                </div>
                <div class="blank-box-wrap">
                    <div class="blank-box-item left-none bottom-none"><input type="text"></div>
                    <div class="blank-box-item left-none bottom-none"><input type="text" v-model="user.phone"></div>
                </div>
                <div class="table-title-box">
                    <div class="table-title-box-item left-none bottom-none">专业</div>
                    <div class="table-title-box-item left-none bottom-none">家庭电话</div>
                </div>
                <div class="blank-box-wrap">
                    <div class="blank-box-item left-none height-width2 bottom-none"><input type="text"></div>
                    <div class="blank-box-item left-none height-width2 bottom-none"><input type="text"></div>
                </div>
            </div>
            <div class="table-tr">
                <div class="table-title-box">
                    <div class="table-title-box-item bottom-none">身份证地址</div>
                </div>
                <div class="blank-box-wrap height-width3">
                    <div class="blank-box-item left-none height-width3 bottom-none"><input type="text" v-model="user.caddress"></div>
                </div>
                <div class="table-title-box">
                    <div class="table-title-box-item left-none bottom-none">现住址</div>
                </div>
                <div class="blank-box-wrap height-width4">
                    <div class="blank-box-item left-none height-width4 bottom-none"><input type="text" v-model="user.address"></div>
                </div>
            </div>
            <div class="table-tr">
                <div class="table-title-box table-title-box2">
                    <div class="table-title-box-item table-title-box-item2 bottom-none">与以前单位是否有过劳<br/>动仲裁或诉讼案件</div>
                </div>
                <div class="blank-box-wrap height-width3">
                    <div class="blank-box-item high-height2 left-none height-width3 bottom-none">
                        <div class="marriage-check-box">
                            <el-radio v-model="radio" label="2">是</el-radio>
                            <el-radio v-model="radio" label="2">否</el-radio>
                            如是，请详细说明：
                        </div>
                    </div>
                </div>
                <div class="table-title-box table-title-box2">
                    <div class="table-title-box-item table-title-box-item2 left-none bottom-none">
                        是否曾因工作有胜任或品行<br/>不佳而被解雇？
                    </div>
                </div>
                <div class="blank-box-wrap height-width5">
                    <div class="blank-box-item high-height2 left-none height-width5 bottom-none">
                        <div class="marriage-check-box">
                            <el-radio v-model="radio" label="2">是</el-radio>
                            <el-radio v-model="radio" label="2">否</el-radio>
                            如是，请详细说明：
                        </div>
                    </div>
                </div>
            </div>
            <div class="table-tr">
                <div class="table-title-box table-title-box3">
                    <div class="table-title-box-item table-title-box-item6 bottom-none">
                        <div class="table-title-box-item6-title">是否有犯罪记录？</div>
                        <div class="table-title-box-item6-blank"><input type="text"></div>
                    </div>
                    <div class="table-title-box-item table-title-box-item5 bottom-none">
                        <div class="marriage-check-box">
                          您接受公司对新人三天内主动离职无薪的管理吗？<el-radio v-model="radio" label="1">接受</el-radio><el-radio v-model="radio" label="2">不接受</el-radio><br/>
                          您接受公司由于行业特征实行单休的管理方式吗？<el-radio v-model="radio" label="1">接受</el-radio><el-radio v-model="radio" label="2">不接受</el-radio>
                        </div>
                    </div>
                </div>
                <div class="blank-box-wrap high-width4">
                    <div class="blank-box-item left-none high-width4 bottom-none">
                        <div class="marriage-check-box high-width4" style="padding-left:10px;">
                            个人原因工作没有完成，是否愿意自<br/>动自发无薪加班完成<br/>
                            <el-radio v-model="radio" label="1">愿意</el-radio>
                            <el-radio v-model="radio" label="2">不愿意</el-radio>
                        </div>
                    </div>
                </div>
            </div>
            <div class="table-tr">
                <div class="table-title-box table-title-box2">
                    <div class="table-title-box-item table-title-box-item2 bottom-none">和原单位劳动关系</div>
                    <div class="table-title-box-item table-title-box-item9">
                        <div class="item9-left-box">
                            招聘要求
                        </div>
                        <div class="item9-right-box">
                            试用期
                        </div>
                    </div>
                </div>
                <div class="blank-box-wrap high-width5">
                    <div class="blank-box-item high-height3 left-none high-width5 bottom-none">
                        <div class="marriage-check-box">
                            <el-radio v-model="radio" label="2">已解除：</el-radio>
                            <el-radio v-model="radio" label="2">签约期：</el-radio>
                            <el-radio v-model="radio" label="2">其他：</el-radio>
                        </div>
                    </div>
                    <div class="blank-box-item blank-box-item2 high-height3 left-none high-width5 table-title-box-item10">
                        <div class="item10-left"></div>
                        <div class="item10-center">转正期</div>
                        <div class="item10-right">
                            <div class="right-item-top">基薪：</div>
                            <div class="right-item-bottom">奖金：</div>
                        </div>
                    </div>
                </div>
                <div class="table-title-box table-title-box4">
                    <div class="table-title-box-item table-title-box-item7 left-none bottom-none">是否会驾驶</div>
                    <div class="table-title-box-item table-title-box-item7 left-none">可到岗时间</div>
                </div>
                <div class="blank-box-wrap high-width6">
                    <div class="blank-box-item high-height3 left-none high-width6 bottom-none">
                        <div class="marriage-check-box">
                            <el-radio v-model="radio" label="2">会</el-radio><br/>
                            <el-radio v-model="radio" label="2">不会</el-radio>
                        </div>
                    </div>
                    <div class="blank-box-item high-height3 left-none high-width6">
                    </div>
                </div>
                <div class="table-title-box table-title-box5">
                    <div class="table-title-box-item table-title-box-item8 left-none">
                        <div class="marriage-check-box">
                            入职能提供的证件：
                            <el-checkbox-group v-model="zjList">
                                <el-checkbox label="身份证；" ></el-checkbox>
                                <el-checkbox label="毕业证；" ></el-checkbox>
                                <el-checkbox label="学位证；" ></el-checkbox>
                                <el-checkbox label="驾照；" ></el-checkbox>
                                <el-checkbox label="资格证；" ></el-checkbox>
                                <el-checkbox label="离职证明；" ></el-checkbox>
                                <el-checkbox label="其他；" ></el-checkbox>
                            </el-checkbox-group>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- 工作经历 -->
        <div class="base-msg-wrap" v-if="tabChecked == 1">
            <div class="table-tr">
              <div class="table-title-box">
                <div class="table-title-box-item table-title-box-item11">工作经历</div>
              </div>
              <div class="blank-box-wrap">
                <div class="blank-box-item3 left-none bottom-none">时间</div>
                <div class="blank-box-item3 left-none bottom-none"><input type="text" v-model="experienceList[0].data"></div>
                <div class="blank-box-item3 left-none bottom-none"></div>
                <div class="blank-box-item3 left-none"></div>
              </div>
              <div class="blank-box-wrap">
                <div class="blank-box-item3 left-none bottom-none">工作单位名称</div>
                <div class="blank-box-item3 left-none bottom-none"><input type="text" v-model="experienceList[0].unit"></div>
                <div class="blank-box-item3 left-none bottom-none"></div>
                <div class="blank-box-item3 left-none"></div>
              </div>
              <div class="blank-box-wrap">
                <div class="blank-box-item3 left-none bottom-none">月薪</div>
                <div class="blank-box-item3 left-none bottom-none"><input type="text" v-model="experienceList[0].wages"></div>
                <div class="blank-box-item3 left-none bottom-none"></div>
                <div class="blank-box-item3 left-none"></div>
              </div>
              <div class="blank-box-wrap">
                <div class="blank-box-item3 left-none bottom-none">直属主管姓名</div>
                <div class="blank-box-item3 left-none bottom-none"><input type="text" v-model="experienceList[0].manager"></div>
                <div class="blank-box-item3 left-none bottom-none"></div>
                <div class="blank-box-item3 left-none"></div>
              </div>
              <div class="blank-box-wrap">
                <div class="blank-box-item3 left-none bottom-none">主管电话</div>
                <div class="blank-box-item3 left-none bottom-none"><input type="text" v-model="experienceList[0].managerphone"></div>
                <div class="blank-box-item3 left-none bottom-none"></div>
                <div class="blank-box-item3 left-none"></div>
              </div>
              <div class="blank-box-wrap">
                <div class="blank-box-item3 left-none bottom-none">离职原因及时间</div>
                <div class="blank-box-item3 left-none bottom-none"><input type="text" v-model="experienceList[0].depart"></div>
                <div class="blank-box-item3 left-none bottom-none"></div>
                <div class="blank-box-item3 left-none"></div>
              </div>
              <!-- <div class="table-title-box">
                <div class="table-title-box-item left-none bottom-none">曾用名</div>
                <div class="table-title-box-item left-none bottom-none">出生日期</div>
                <div class="table-title-box-item left-none bottom-none">体重</div>
              </div>
              <div class="blank-box-wrap  hight-width">
                <div class="blank-box-item hight-width left-none bottom-none"></div>
                <div class="blank-box-item hight-width left-none bottom-none"></div>
                <div class="blank-box-item hight-width left-none bottom-none">kg</div>
              </div> -->
            </div>
        </div>
        <!-- 家庭成员 -->
        <div class="base-msg-wrap" v-if="tabChecked == 2">
            <div class="table-tr">
              <div class="table-title-box">
                <div class="table-title-box-item table-title-box-item11">家庭成员</div>
              </div>
              <div class="blank-box-wrap">
                <div class="blank-box-item3 left-none bottom-none">关系</div>
                <div class="blank-box-item3 left-none bottom-none"></div>
                <div class="blank-box-item3 left-none bottom-none"></div>
                <div class="blank-box-item3 left-none"></div>
              </div>
              <div class="blank-box-wrap">
                <div class="blank-box-item3 left-none bottom-none">姓名</div>
                <div class="blank-box-item3 left-none bottom-none"></div>
                <div class="blank-box-item3 left-none bottom-none"></div>
                <div class="blank-box-item3 left-none"></div>
              </div>
              <div class="blank-box-wrap">
                <div class="blank-box-item3 left-none bottom-none">年龄</div>
                <div class="blank-box-item3 left-none bottom-none"></div>
                <div class="blank-box-item3 left-none bottom-none"></div>
                <div class="blank-box-item3 left-none"></div>
              </div>
              <div class="blank-box-wrap">
                <div class="blank-box-item3 left-none bottom-none">电话</div>
                <div class="blank-box-item3 left-none bottom-none"></div>
                <div class="blank-box-item3 left-none bottom-none"></div>
                <div class="blank-box-item3 left-none"></div>
              </div>
              <div class="blank-box-wrap">
                <div class="blank-box-item3 left-none bottom-none">职业</div>
                <div class="blank-box-item3 left-none bottom-none"></div>
                <div class="blank-box-item3 left-none bottom-none"></div>
                <div class="blank-box-item3 left-none"></div>
              </div>
              <div class="blank-box-wrap">
                <div class="blank-box-item3 left-none bottom-none">详细地址</div>
                <div class="blank-box-item3 left-none bottom-none"></div>
                <div class="blank-box-item3 left-none bottom-none"></div>
                <div class="blank-box-item3 left-none"></div>
              </div>
            </div>
        </div>
        <div @click="sendMsg" class="send-but">发送</div>
    </div>
</template>
<script>
export default {
  name: 'demo',
  data () {
    return {
      tabChecked: 0,
      zjList: [],
      marriage: '1',
      user: {
        username: '',
        sex: '',
        birthday: '',
        school: '',
        education: '',
        speciality: '',
        idcar: '',
        phone: '',
        address: '',
        caddress: '',
        work: '',
        msg: '',
        serialVersionUID: ''
      },
      experienceList: [
        {
          data: '',
          unit: '',
          wages: null,
          manager: '',
          managerphone: '',
          depart: '',
          serialVersionUID: ''
        }
      ],
      familyList: [
        {
          relationship: 'String',
          name: 'String',
          age: 0,
          phone: 'String',
          career: 'String',
          address: 'String'
        }
      ],
      trainingList: [
        {
          schoolname: 'String',
          education: 'String',
          data: 'String',
          certificate: 'String',
          serialVersionUID: '0'
        }
      ]
    }
  },
  methods: {
    sendMsg () {
      const params = {
        user: this.user,
        experienceList: this.experienceList
      }
      this.$http.post('http://192.168.2.21/user/add', params).then(res => {
        alert('添加成功')
      })
    }
  }
}
</script>
<style lang="stylus">
*
  font-size: 16px
  box-sizing: border-box
input
  width 80px
  width 100%
  height 100%
  border none
.send-but{
    cursor pointer
    width: 100px;
    height 50px
    color red
    font-size 20px
    line-height: 50px
    text-align center
    background-color yellow
    border-radius: 5px
    margin-top: 20px;
    margin-left: 20px;
}
.bottom-none{
    border-bottom: none !important
}
.top-none{
    border-top: none !important
}
.left-none{
    border-left: none !important
}
.right-none{
    border-right: none !important
}
.height-box{
    height 60px !important
    line-height: 60px
}
.double-line{
    line-height: 20px
    padding-top 10px
    box-sizing: border-box
}
.hight-width{
    width: 200px !important
}
.height-width2{
    width: 400px !important
}
.height-width3{
    width: 200px !important
}
.high-width4{
    width: 300px !important
    height 72px !important
}
.high-width5{
    width: 280px !important
    height 30px !important
}
.high-width6{
    width: 80px !important
    height 30px !important
}
.height-width4{
    width: 500px !important
}
.height-width5{
    width: 300px !important
}
.high-height2{
    height 50px !important
}
.high-height3{
    height 50px !important
}
.political-Landscape
    display: flex
    line-height: 25px
    .little-blank-box
        width: 80px
    .little-title-box
        width: 40px
        border-left: 1px solid black
    .little-blank-box2
        width: 80px
        border-left: 1px solid black
.head-img-box
  width: 200px
  height 126px
  border: 1px solid black
  border-left: none
  border-bottom: none
.big-wrap
  .table-title
    width: 100%
    font-size: 18px
    text-align: center
  .table-tab-box
    width: 100%
    display: flex
    margin-bottom 20px
    .tab-tab-item
      padding: 0 10px
      text-align: center
      font-size: 16px
      cursor pointer
    .actived
      color: red
  .base-msg-wrap
    width: 100%
    padding: 0 10px
    .table-tr
      width: 100%
      display: flex
      .table-title-box
        width: 100px
        .table-title-box-item
            border: 1px solid black
            text-align: center
            height 22px
        .table-title-box-item2
          height 50px
          width: 200px !important
        .table-title-box-item3
          height 22px
          width: 180px !important
        .table-title-box-item4
          height 50px
          width: 180px !important
        .table-title-box-item5
          height 50px
          width: 600px !important
        .table-title-box-item6
          height 22px
          width: 600px !important
          display: flex
          .table-title-box-item6-title
            width: 200px
          .table-title-box-item6-blank
            border: 1px solid black
            border-top: none
            border-right: none
            height 22px
            width: 400px
        .table-title-box-item7
          height 50px
          width: 50px !important
        .table-title-box-item8
          height 100px
          width: 290px !important
        .table-title-box-item9
          height 50px
          width: 200px !important
          display: flex
          line-height: 50px
          .item9-left-box, .item9-right-box
            width: 100px
          .item9-left-box
            border-right: 1px solid black
        .table-title-box-item10
          height 50px
          width 280px !important
          display flex !important
        .table-title-box-item11
          height 150px
          width 100px !important
          line-height: 150px
      .blank-box-wrap
        width: 100px
        .blank-box-item
          height 22px
          border: 1px solid black
          text-align: center
        .blank-box-item2
          display: flex
          height 50px
          width 280px !important
          display flex !important
          .item10-left
            width 80px !important
            height 50px !important
            border-right: 1px solid black
          .item10-center
            width 100px !important
            height 50px !important
            line-height 50px
            border-right: 1px solid black
          .item10-right
            width 100px !important
            height 50px !important
            .right-item-top
              width: 100px
              height: 25px
              border-bottom 1px solid black
              text-align: left
              margin-left: 10px
            .right-item-bottom
              width: 100px
              height: 25px
              text-align: left
              margin-left: 10px
        .blank-box-item3
          height 37.5px
          border: 1px solid black
          text-align: center
    .table-title-box2
        width: 200px !important
    .table-title-box3
        width: 600px !important
    .table-title-box4
        width: 50px !important
    .table-title-box5
        width: 290px !important
.marriage-check-box
  line-height: 20px
  text-align: left !important
  .el-radio
    margin-right 0
    margin-left: 10px
  .el-checkbox
    margin-right 10px
</style>
