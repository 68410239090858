<template>
  <div>
    <router-view v-if="showRouter" key="$route.fullPath"></router-view>
  </div>
</template>
<script>
export default {
  name: 'app',
  provide () {
    return {
      reload: this.reload
    }
  },
  data () {
    return {
      showRouter: true
    }
  },
  methods: {
    reload () {
      this.showRouter = false
      this.$nextTick(function () {
        this.showRouter = true
      })
    }
    // getinfo () {
    //   this.axios.get('/api/api/api.php?return=json').then(res => {
    //     console.log(res)
    //   })
    // },
    // wangyy () {
    //   this.axios.get('https://v1.alapi.cn/api/music/search?keyword=爱河').then(res => {
    //     console.log(res)
    //   })
    // }
  },
  mounted () {
    // this.getinfo()
    // this.wangyy()
  }
}
</script>
<style lang="stylus">

</style>
